/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v27-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/roboto-v27-latin-ext_latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/roboto-v27-latin-ext_latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/roboto-v27-latin-ext_latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/roboto-v27-latin-ext_latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/roboto-v27-latin-ext_latin-regular.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v27-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/roboto-v27-latin-ext_latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/roboto-v27-latin-ext_latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/roboto-v27-latin-ext_latin-700.woff') format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/roboto-v27-latin-ext_latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/roboto-v27-latin-ext_latin-700.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
