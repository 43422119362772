@import 'shared-web/styles/variables';

.wrapper {
  position: relative;
}

.input {
  background-color: $white;
  border-radius: 4px;
  height: 48px;
  width: 400px;
  padding: 0 $m;
  border: 1px solid $silver;
}

.search_icon {
  top: $s;
  right: $m;
  position: absolute;
}

.delete_icon {
  top: $s;
  right: $m;
  position: absolute;
  pointer-events: all;
}
