@import 'shared-web/styles/variables';

.footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.bank {
  margin-bottom: $l;
}
