@import 'shared-web/styles/variables';

.footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.help_logo {
  width: 24px;
  height: 24px;
}

.gutter {
  width: $l;
}

.two_columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $l;
}

.header {
  margin-bottom: $l;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: $xl;
}

.status_row {
  display: flex;
  flex: 1;
  gap: $s;
}

.delete {
  display: flex;
  height: 24px;
  width: 24px;
  cursor: pointer;

  &__disable {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.spinner {
  margin-left: 37%;
  position: absolute;
}

.text_button {
  display: flex;
  background-color: transparent;
  margin: $s 0 $l 0;
  border: 0;
  padding: 0;
}

.icon {
  padding-right: $s;
  cursor: pointer;
}

.margin_left {
  margin-left: $m;
}

.button_row {
  display: flex;
  flex: 1;
  gap: $s;
  margin: $m 0 $s 0;
}
