@import 'shared-web/styles/variables';

.error {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $s;

  .icon {
    fill: $alert;
  }
}

.simple_display {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}
