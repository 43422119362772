@import 'shared-web/styles/variables';

.text {
  margin-top: $m;
}

.error_details {
  margin-bottom: $l;
  border: 1px solid $separator;
  width: 100%;
  display: block;
  font-family: monospace;
  white-space: pre;
  text-align: start;
  overflow: auto;
}
