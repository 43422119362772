@import 'shared-web/styles/variables';

.content {
  width: 100%;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 0 22px rgb(0 0 0 / 11%);
  display: flex;
  flex-direction: column;
  padding: $xl;
  flex: 1;
}

.heading {
  margin-bottom: $l;
}

.link {
  color: $dark_primary;

  &:visited {
    color: $dark_primary;
  }
}
