@import 'shared-web/styles/variables';

.two_columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $l;
}

.button_row {
  display: flex;
  flex: 1;
  gap: $s;
  margin: $m 0 $s 0;
}

.link_wrapper {
  margin-bottom: $l;
}

.link_label {
  margin-bottom: $m;
}

.email_link {
  margin-bottom: $m;
}

.review_notice {
  margin-bottom: $m;
  border-left: $xs solid $alert;
  padding: $s;
  border-radius: $xs 0 0 $xs;
  background-color: $grey;
}
