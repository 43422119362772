@import 'shared-web/styles/variables';

.content {
  width: 100%;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 0 22px rgb(0 0 0 / 11%);
  display: flex;
  flex-direction: column;
  padding: $xl;
  flex: 1;
}

.heading {
  margin-bottom: $l;
}

.details_text {
  margin-bottom: $l;
}

.download_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date_input {
  width: 270px;
}
