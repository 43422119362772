@import 'shared-web/styles/variables';

.container {
  margin-top: auto;
  width: 100%;
  padding: $xl $m 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: $s;
}

.text {
  display: flex;
  align-items: center;
  color: $dim;
  font-size: 14px;
}

.button {
  border: 1px solid $silver;
  border-right: 0;
  background-color: $white;
  padding: auto 15px;
  color: $dim;
  font-size: 14px;
  width: 40px;
  height: 40px;

  &__left {
    border-radius: 4px 0 0 4px;
  }

  &__right {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid $silver;
  }
}

.text_button {
  height: 40px;
  width: 120px;
  border: 1px solid $silver;
  border-radius: 4px;
  background-color: $white;
  color: $dim;
  padding: auto 15px;
  font-size: 14px;

  &__left {
    margin-right: $m;
  }

  &__right {
    margin-left: $m;
  }
}

.active {
  background-color: $primary;
  color: $white;
  border: 1px solid $primary;
}

.disabled {
  color: $silver;
}
