@import 'shared-web/styles/variables';

.container {
  margin-top: $l;
  display: flex;
  align-items: center;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: $xs;
}

.email_address {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
