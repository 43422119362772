@import 'shared-web/styles/variables';

.header {
  background-color: $super_light_grey;
  height: 48px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: 0;
}
