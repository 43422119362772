@import 'shared-web/styles/variables';

.two_columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $l;
}

.footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
