@import 'shared-web/styles/variables';

.container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: $primary;
}

.content {
  padding: $xxl3 $xl 0 $xl;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
