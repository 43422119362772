@import 'shared-web/styles/variables';

html {
  overscroll-behavior: none;

  /*
    Prevent page from "jumping"
    it happens when opening a row menu popup on Firefox
  */
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

p {
  margin: 0;
}

html,
body,
#root {
  background-color: $grey;
}

#root {
  display: flex;
}
