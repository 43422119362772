@import 'shared-web/styles/variables';

.button {
  display: flex;
  min-height: $xxl;
  border-width: 0;
  border-radius: 4px;
  padding: 0 $m 0 $m;
  background-color: $primary;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}
