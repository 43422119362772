@import 'shared-web/styles/variables';

.footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: $s;
}
