@import 'shared-web/styles/variables';

.content {
  width: 100%;
  background-color: $white;
  border-radius: $xxs;
  box-shadow: 0 0 22px rgb(0 0 0 / 11%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $xl;
  flex: 1;
}
