@import 'shared-web/styles/variables';

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead {
  background-color: $super_light_grey;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $aluminium;
}

.th {
  height: 48px;
  padding-left: $m;
}

.tr {
  height: $xxl2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $aluminium;
  color: $dim;
}

.cell {
  margin-left: $m;
  display: inline-block;
}

.empty {
  background-color: $aluminium;
  color: $aluminium;
  min-height: 18px;
  margin-left: $m;
}

.text {
  margin-top: $l;
  white-space: pre-wrap;
  line-height: 22px;
}

.disabled {
  color: $silver;

  &:hover {
    background-color: transparent;
    color: $silver;
  }
}

.pointer {
  cursor: pointer;
}

.boolean {
  display: flex;
  justify-content: center;
  width: $xxl2;
}
