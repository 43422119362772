@import 'shared-web/styles/variables';

.sidebar {
  max-width: 252px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $xl;
  overflow-y: auto;
}

.logo {
  width: 100%;
  min-width: 133px;
  height: auto;
  margin-bottom: 122px;
}

.divider {
  border: solid 1px $lighter_grey;
  margin: $xl 0;
  opacity: 1;
}

.item_wrapper {
  display: flex;
  flex-direction: column;
  gap: $s;
}
